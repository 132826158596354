// Gestion About
const aboutContainer = document.querySelector(".about")
const contactContainer = document.querySelector(".contact")
const aboutButton = document.querySelector(".about__button")
const contactButton = document.querySelector(".contact__button")
const homepage = document.querySelector(".homepage")
const email = document.querySelector(".contact__email")


aboutButton.addEventListener("click", displayAbout)
contactButton.addEventListener("click", displayContact)
homepage.addEventListener("click", hidePopup)
email.addEventListener("click", copyContent)

function displayAbout(){
    aboutContainer.classList.remove("hidden")
    setTimeout(function() {aboutContainer.classList.add('open')}, 1000)
}
function displayContact(){
    contactContainer.classList.remove("hidden")
    setTimeout(function() {contactContainer.classList.add('open')}, 1000)
}
function hidePopup(){
    var isOpenAbout = aboutContainer.classList.contains("open")
    if(isOpenAbout){
        aboutContainer.classList.replace("open", "hidden")
    }
    var isOpenContact = contactContainer.classList.contains("open")
    if(isOpenContact){
        contactContainer.classList.replace("open", "hidden")
    }
}


    let copyText = email.innerHTML
    async function copyContent() {
        try {
          await navigator.clipboard.writeText(copyText);
          console.log('Content copied to clipboard');
          email.innerHTML = "copié"
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }

